import React from "react"
import Seo from "../components/molecules/Seo"
import Container from "../components/atoms/Container"
import { withTheme } from "styled-components"
import ContentHolder from "../components/organisms/ContentHolder"
import ClientLogos from "../components/organisms/ClientLogos"
import People from "../components/organisms/People"
import styled from "styled-components"
import Splash from "../components/organisms/Splash"
import Illustration from "../assets/about.inline.svg"
import SlideInWrapper from "../components/molecules/SlideInWrapper"
import ArrowText from "../components/atoms/ArrowText"
import Image from "../components/atoms/Image"
import thumb from "../../static/images/cw_home.png"
import StudioHistory from "../components/organisms/StudioHistory"

const Text = styled.div`
  background-color: ${(props) =>
    props.first ? props.theme.colours.white : "none"};
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  margin-top: 2rem;
  @media (${(props) => props.theme.breakpoints.sm}) {
    margin-top: 0;
    padding: ${(props) => (props.first ? "1rem" : 0)};
  }
  @media (${(props) => props.theme.breakpoints.md}) {
    padding: ${(props) => (props.first ? "2rem" : 0)};
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
  }
  @media (${(props) => props.theme.breakpoints.md}) {
    margin: ${(props) => (props.first ? "0 -3rem" : 0)};
  }
`

const TextBlock = styled.div`
  h2 {
    margin-bottom: 1rem;
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const IlloHolder = styled.div`
  width: 100%;
  height: 30vh;
  position: relative;
  overflow: hidden;
  margin: 4rem 0 6rem 0;
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-50%);
    display: flex;
    svg {
      height: 100%;
      width: auto;
    }
  }
`

const Badge = styled.div`
  margin-bottom: -3rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 1rem;
  .gatsby-image-wrapper {
    grid-column: 6/9;
  }
`

const About = ({ theme }) => (
  <>
    <Seo
      title="About | Craig Walker"
      thumb={thumb}
      description="Craig Walker designs and researches for the world’s leading organisations, working in the technology, financial services, built environment and infrastructure sectors."
    />
    <Splash
      bg={theme.colours.blue}
      text={
        <>
          <b>We’re designers</b> and <br />
          design-thinkers.
        </>
      }
    >
      <ArrowText>
        <p>
          Craig Walker designs and researches for the world’s leading
          organisations, working in the technology, financial services, built
          environment, health and infrastructure sectors. Our multidisciplinary team of
          craft-based designers applies their expertise and experience to help
          clients create new opportunities and solve challenges.
        </p>
      </ArrowText>
    </Splash>
    <ContentHolder>
      <Container>
        <Text first>
          <TextBlock>
            <h2 className="h5 sans">
              <b>Multi-disciplinary</b>
            </h2>
            <p>
              We’re designers from a broad range of backgrounds and disciplines
              creating world-class strategic, service, digital and data design.
            </p>
          </TextBlock>
          <TextBlock>
            <h2 className="h5 sans">
              <b>Designers of things</b>
            </h2>
            <p>
              We create clear solutions that are designed and tested with
              customers and staff, fit for purpose and defined to a fidelity
              that can be developed and implemented.
            </p>
          </TextBlock>
          <TextBlock>
            <h2 className="h5 sans">
              <b>Collaborative</b>
            </h2>
            <p>
              We work alongside leaders, teams and organisations, combining our
              expertise with theirs to design solutions and meaningful
              experiences.
            </p>
          </TextBlock>
          <TextBlock>
            <h2 className="h5 sans">
              <b>Out to make a difference</b>
            </h2>
            <p>
              On every project, big or small, we bring to bear our mission: to
              create the most positive impact for the most number of people.
            </p>
          </TextBlock>
        </Text>
      </Container>
      <SlideInWrapper>
        <IlloHolder>
          <div className="inner">
            <Illustration />
            <Illustration />
            <Illustration />
            <Illustration />
            <Illustration />
          </div>
        </IlloHolder>
      </SlideInWrapper>
      <SlideInWrapper>
        <Container>
          <Badge>
            <Image imgName="badge.png" />
          </Badge>
        </Container>
      </SlideInWrapper>
      <Container>
        <SlideInWrapper>
          <Text>
            <TextBlock>
              <h2 className="h5 sans">
                <b>We work globally</b>
              </h2>
              <p>
                From our studios in Sydney, Melbourne and Singapore, we work
                with clients from around the world and across a broad range of
                industries.
              </p>
            </TextBlock>
          </Text>
        </SlideInWrapper>
      </Container>
      <ClientLogos />
      <People />
      <StudioHistory />
    </ContentHolder>
  </>
)

export default withTheme(About)
