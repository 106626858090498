import React from "react"
import styled from "styled-components"
import Container from "../atoms/Container"
import { peopleList } from "../../data/peopleList"
import SlideInWrapper from "../molecules/SlideInWrapper"

const IntroHolder = styled.div`
  flex: 0.7;
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin-right: 3rem;
  }
`

const OuterContainer = styled.div`
  background-color: #f7f7f6;
  padding: 4rem 0;
`

const Intro = styled.div`
  width: 100%;
  .h5 {
    margin-top: 0;
  }
`

const Holder = styled.div`
  flex: 1;
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 2rem 0;
  @media (${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (${(props) => props.theme.breakpoints.lg}) {
    margin: 0;
  }
`

const PersonTile = styled.div`
  position: relative;
`

const OuterHolder = styled.div`
  display: flex;
  flex-direction: column;
  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row;
  }
`

const Text = styled.div`
  h3,
  p {
    margin: 0;
  }
  .bio {
    margin-top: 2rem;
    @media (${(props) => props.theme.breakpoints.xl}) {
      margin-top: auto;
    }
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

function People() {
  return (
    <OuterContainer>
      <Container>
        <OuterHolder>
          <IntroHolder>
            <SlideInWrapper>
              <Intro>
                <h2 className="h5 sans">
                  <b>Our Team</b>
                </h2>
                <p>
                  Craig Walker’s team of multidisciplinary, craft-based
                  designers apply their expertise and experience to help clients
                  create new opportunities and solve challenges.
                </p>
              </Intro>
            </SlideInWrapper>
          </IntroHolder>
          <Holder>
            {peopleList.map((person, i) => (
              <SlideInWrapper key={i}>
                <PersonTile>
                  <Text>
                    <p>
                      <b>{person.name}</b>
                    </p>
                    <p>{person.role}</p>
                  </Text>
                </PersonTile>
              </SlideInWrapper>
            ))}
          </Holder>
        </OuterHolder>
      </Container>
    </OuterContainer>
  )
}

export default People
