import React from "react"
import styled from "styled-components"
import SlideInWrapper from "../molecules/SlideInWrapper"
import Container from "../atoms/Container"
import IconArrow from "../atoms/iconArrow"
import Image from "../atoms/Image"

const Holder = styled.div`
  margin: 6rem 0;
`

const TextHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 0 0 4rem;
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: 1fr 1fr;
  }
`

const Text = styled.div`
  @media (${(props) => props.theme.breakpoints.md}) {
    grid-column: 1/3;
  }
  @media (${(props) => props.theme.breakpoints.lg}) {
    grid-column: 1/2;
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const MoreLink = styled.p`
  margin: 4rem 0 0;
  svg {
    margin-left: 2rem;
    margin-right: 0rem !important;
  }

  a {
    justify-content: end;
  }
`

function StudioHistory() {
  return (
    <Container>
      <Holder>
        <SlideInWrapper>
          <TextHolder>
            <Text>
              <p>
                <strong>Kernow Craig</strong> (L) and{" "}
                <strong>Jeremy Walker</strong> (R) launched Craig Walker in 2016
                to fill the yawning gap they saw between strategic solutions
                that never see the light of day and solutions for problems
                people don't have. That gap is a design project. For them,
                design is design, whether it's for a strategy, a brand, a
                service or a product.
              </p>
              <p>
                With more than 20 years as a creative designer, Kernow has
                worked for multinational and boutique clients around the world.
              </p>
              <p>
                Jeremy has global experience as a design director leading
                inhouse and agency teams in Australia and the UK , working with
                clients such as BBC, UK Home Office, Barclays, Westpac and IAG.
              </p>
            </Text>
          </TextHolder>
        </SlideInWrapper>
        <SlideInWrapper>
          <Image imgName="kernow-jeremy.jpg" />
          <MoreLink>
            <a className="arrow-link" href="mailto:hello@craigwalker.com.au">
              Need to know more? Get in touch
              <IconArrow />
            </a>
          </MoreLink>
        </SlideInWrapper>
      </Holder>
    </Container>
  )
}

export default StudioHistory
