import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import SlideInWrapper from '../molecules/SlideInWrapper';
import { StaticQuery, graphql } from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";

const Holder = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  margin: 5rem 0 10rem 0;
  @media( ${props => props.theme.breakpoints.sm} ) {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 4rem;
  }
`;

const LogoHolder = styled.div`
  width: 100%;
  max-height: 4rem;
`;

function ClientLogos() {
  return (
    <Container>
      <SlideInWrapper>
        <Holder>
          <StaticQuery
                query={graphql`
                  query LogoQuery {
                    allImageSharp(
                        filter: {fluid: {originalName: {glob: "*colour-logo.png"}}},
                        sort: {fields: fluid___originalName, order: ASC}
                        ) {
                      nodes {
                        id
                        resize {
                          originalName
                        }
                        gatsbyImageData(
                          width: 300
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                         )
                      }
                    }
                  }
                `}
                render={data => data.allImageSharp.nodes.map(node =>
                  <LogoHolder key={node.id}>
                    <GatsbyImage
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      imgStyle={{
                        objectFit: 'contain',
                        objectPosition: `center center`,
                      }}
                      image={node.gatsbyImageData}/>
                  </LogoHolder>
                )}
              />
        </Holder>
      </SlideInWrapper>
    </Container>
  )
}

export default ClientLogos;
