export const peopleList = [
  {
    name: "Jeremy Walker",
    role: "Director",
    image: "people-01.jpg",
    bio:
      "Jeremy has led inhouse and agency teams working across service and digital design.  Prior to co-founding Craig Walker, he held senior roles in human-centred design with IAG and Westpac, and was a lead consultant with live|work, a leading London service design agency.",
  },
  {
    name: "Kernow Craig",
    role: "Director",
    image: "people-03.jpg",
    bio:
      "A design director with 20 years of practice, Kernow has worked around the world and with some of the most recognised global and Australian brands. Before launching Craig Walker, he was the stream lead for customer-centred design with the Westpac Group.",
  },
  {
    name: "Fiona Morrison",
    role: "Studio Lead Melbourne",
    image: "people-08.jpg",
    bio:
      "Fiona is experienced in leading large-scale engagement and design programs across brand, product and customer experience. Before Craig Walker, she held senior roles with major airlines in the US, the Middle East and South America.\n",
  },
  {
    name: "Marianna Agostino",
    role: "Finance Director",
    image: "",
    bio: "",
  },
  {
    name: "Aden Rolfe",
    role: "Creative Lead Content",
    image: "",
    bio: "",
  },
  {
    name: "James de Vries",
    role: "Lead Design Consultant",
    image: "",
    bio: "",
  },
  {
    name: "Erich Domingo",
    role: "Creative Lead Interaction",
    image: "",
    bio: "",
  },
  {
    name: "Morgan Thomas",
    role: "Senior Interaction Designer",
    image: "",
    bio: "",
  },
  {
    name: "Steve Backmayer",
    role: "Senior Interaction Designer",
    image: "people-11.jpg",
    bio:
      "Wedged snuggly between healthcare and design, Steve is an Interaction Designer and Registered Nurse. His niche combination of skills has seen him both designing digital games and working in medical environments. Steve designs products and services that bring delight to our clients.",
  },
  {
    name: "Maria Angel Villa",
    role: "Interaction Designer",
    image: "people-09.jpg",
    bio:
      "Maria loves to marry her design research skills and 3D design expertise to create experiences that bring value and beauty to users, especially in spaces where the physical and digital intersect. She’s honed her skills working with multidisciplinary teams in Australia, Singapore, Colombia and France.",
  },
  {
    name: "Shenaz Engineer",
    role: "Senior Designer Researcher",
    image: "",
    bio: "",
  },
  {
    name: "Suri Adlina",
    role: "Design Researcher",
    image: "people-10.jpg",
    bio:
      "Suri is a Design Researcher with an intuitive sense of craft, honed from her background in architecture. With a masters in design innovation and technology, she now brings her considerable talents to bear.",
  },
  {
    name: "John-Henry Pajak",
    role: "Lead Visual Designer",
    image: "people-02.jpg",
    bio:
      "John Henry is expert at using his crafts of visual design and illustration to create compelling narratives and to bring customer value propositions to life. His career includes stints in advertising where his work won multiple awards.",
  },
  {
    name: "Peta Farago",
    role: "Lead Visual Designer",
    image: "people-06.jpg",
    bio:
      "An accomplished visual designer, Peta’s experience spans multiple industries from finance to health to fashion. She’s a master at helping clients develop their organisational strategy and turning it into beautifully designed products and services.",
  },
  {
    name: "Sam Donaldson",
    role: "Visual Designer",
    image: "",
    bio: "",
  },
  {
    name: "Jake Porter",
    role: "Visual Designer",
    image: "people-12.jpg",
    bio:
      "Jake is Visual Designer with a super power in illustration. His career has taken him across a wide range of industries working in all aspects of design, including animation, identity development, website design and, of course, illustration.",
  },
  {
    name: "Wendy Egginton",
    role: "Finance",
    image: "",
    bio: "",
  },
  {
    name: "Emily Meller",
    role: "Content Designer",
    image: "",
    bio: "",
  },
]
